// extracted by mini-css-extract-plugin
export var column = "SignUp__column__sTq8I";
export var container = "SignUp__container__UPhM6";
export var flex = "SignUp__flex__I3dox";
export var flexColumn = "SignUp__flexColumn__vQi9l";
export var gap1 = "SignUp__gap1__fMmAN";
export var gap2 = "SignUp__gap2__kdFcX";
export var gap3 = "SignUp__gap3__k6q42";
export var gap4 = "SignUp__gap4__LITKT";
export var gap5 = "SignUp__gap5__G0sp1";
export var helpContainer = "SignUp__helpContainer__r2E7x";
export var info = "SignUp__info__ZnhDn";
export var messageContainer = "SignUp__messageContainer__dmytt";
export var row = "SignUp__row__IlfYw";
export var signUp = "SignUp__signUp__zpeql";
export var signUpContainer = "SignUp__signUpContainer__ac5A2";